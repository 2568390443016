
.ql-toolbar {
  .ql-snow {
    border: none !important;
  }
}

.ql-container {
  font-family: inherit !important;;
  font-size: inherit !important;;
  .ql-snow {
    border: none !important;
  }
}

.ql-snow {
  border: none !important;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0 !important;
}

.ql-editor ul[data-checked=true] > li::before {
  font: normal normal normal 16px/1 "Font Awesome 5 Free" !important;
  content: '\f058' !important;
  color: #5e72e4 !important;
}

.ql-editor ul[data-checked=false] > li::before {
  font: normal normal normal 16px/1 "Font Awesome 5 Free" !important;
  content: '\f111' !important;
  color: #5e72e4 !important;
}

.ql-editor ul[data-checked=true] > li {
  text-decoration: line-through !important;
}
.ql-editor li {
  font-weight: 400 !important;
}

