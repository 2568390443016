.bootstrap-tagsinput .tag a{
  position: absolute;
  top: 50%;
  margin-left: 10px;
  cursor: pointer;
  transform: translate(0, -50%);
  color: transparent;

  &::after {
    font-size: 16px;
    content: "×";
  }
}

.bootstrap-tagsinput .badge {
  position: relative;
  margin: .125rem;
  border-radius: .15rem;
}

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: initial;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}


.bootstrap-tagsinput .badge:hover {
  padding-right: 1.5rem;
    a{
      color: inherit;
    }
}