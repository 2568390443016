$white: #eee;
$bg: #fff;

$horizontal-gutter: 50px;
$border-radius: 10px;

$entry-min-height: 60px;

$label-width: 150px;
$label-height: 30px;
$label-border-radius: 5px;

.level-wrapper {
    position: relative;

  
  .branch {
    position: relative;
    margin-left: $horizontal-gutter + $label-width;
    &:before {
      content: "";
      width: $horizontal-gutter / 2;
      border-top: 1px solid $white;
      position: absolute;
      left: -$horizontal-gutter;
      top: 50%;
      margin-top: 1px;
    }
  }
  
  .entry {
    position: relative;
    min-height: $entry-min-height;
    &:before {
      content: "";
      height: 100%;
      border-left: 1px solid $white;
      position: absolute;
      left: -($horizontal-gutter / 2);
    }
    &:after {
      content: "";
      width: $horizontal-gutter / 2;
      border-top: 1px solid $white;
      position: absolute;
      left: -($horizontal-gutter / 2);
      top: 50%;
      margin-top: 1px;
    }
    &:first-child {
      &:before {
        width: $border-radius;
        height: 50%;
        top: 50%;
        margin-top: 2px;
        border-radius: $border-radius 0 0 0;
      }
      &:after {
        height: $border-radius;
        border-radius: $border-radius 0 0 0;
      }
    }
    &:last-child {
      &:before {
        width: $border-radius;
        height: 50%;
        border-radius: 0 0 0 $border-radius;
      }
      &:after {
        height: $border-radius;
        border-top: none;
        border-bottom: 1px solid $white;
        border-radius: 0 0 0 $border-radius;
        margin-top: -$border-radius + 1px;
      }
    }
    &.sole {
      &:before {
        display: none;
      }
      &:after {
        width: $horizontal-gutter / 2;
        height: 0;
        margin-top: 1px;
        border-radius: 0;
      }
    }
  }
  
  .label {
    font-size: 12px;
    display: block;
    min-width: $label-width;
    max-width: $label-width;
    padding: 5px 10px;
    border-radius: $label-border-radius;
    line-height: $label-height - 5px * 2;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -($label-height / 2);
  }
  
  .current {
    color: #2643e9;
    background-color: #eaecfb;
    cursor:pointer;
  }
  .selected {
    color: #fff;
    background-color: #5e72e4;
    cursor:pointer;
  }
  .eligible {
    color: #1aae6f;
    background-color: #b0eed3;
    cursor:pointer;
  }
  .default {
    background-color: $white;
    color: #212529;
    cursor:pointer;
  }
  
}